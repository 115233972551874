.reveal-loaded .reveal .reveal-header .reveal-phones [class*="reveal-"] {
    opacity: 0 !important;
    animation: none !important;
    transition: 0s !important;
}

.reveal-loaded [class*="reveal-"] {
    animation: revealAnimation 2s cubic-bezier(.5, 0, 0, 1) both;
}

.reveal-loaded .reveal-header {
    animation: revealHeaderAnimation 1s cubic-bezier(.5, 0, 0, 1) both;
}

.reveal-loaded .reveal-phones {
    animation: revealPhonesAnimate 1s cubic-bezier(.5, 0, 0, 1) both;
}

.reveal-loaded .reveal-2 {
    animation-delay: .1s;
}

.reveal-loaded .reveal-3 {
    animation-delay: .2s;
}

.reveal-loaded .reveal-4 {
    animation-delay: .3s;
}

@media (prefers-reduced-motion: reduce) {
    .reveal-loaded [class*="reveal-"] {
        animation: none !important;
    }
}

@keyframes revealAnimation {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes revealHeaderAnimation {
    0% {
        opacity: 0;
        transform: translate(50px, -50px);
    }

    50% {
        opacity: 1;
        transform: translate(-15px, 15px);
    }

    100% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@keyframes revealPhonesAnimate {
    0% {
        opacity: 0;
        transform: translate(0px, 50px);
    }

    50% {
        opacity: 1;
        transform: translate(0px, -15px);
    }

    100% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

/* Add this CSS in your main CSS file or a style block in your React component */
/* Adjust the animation duration and other properties as needed */

.reveal-5 {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards;
  }
  
  .reveal-6 {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards 0.5s;
  }
  
  .reveal-7 {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards 1s;
  }
  
  .reveal-8 {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards 1.5s;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  